import React from "react";
import counterpart from "counterpart";

class ExternalLogin extends React.Component {
    render() {
        const hasError = Boolean(shiftContext.externalLoginError);

        return (
            <div className="external-login">
                {shiftContext.authenticationModes
                    .filter((cfg) => cfg.mode !== "Form")
                    .map((cfg, idx) => (
                        <form
                            key={idx}
                            method="POST"
                            action={`/authentication/externallogin?provider=${cfg.id}&returnUrl=${encodeURIComponent(
                                this.props.referrer
                            )}`}
                        >
                            <button className="btn btn-green" type="submit">
                                {cfg.name || cfg.mode}
                            </button>
                        </form>
                    ))}
                {hasError && <div className="errorMsg">{counterpart(shiftContext.externalLoginError)}</div>}
            </div>
        );
    }
}

ExternalLogin.displayName = "ExternalLogin";

export default ExternalLogin;
